.mandatory-field-note {
  color: rgb(177, 31, 31);
}

.ui.celled.unstackable.center.aligned.table {
  max-width: 27rem;
}

.ui.table td.active {
  background: aliceblue !important;
  border-radius: 0.25rem;
  /* border: blue solid 3px; */
  z-index: 10;
  box-shadow: 0px 0px 7px #585864;
}

.field.date-input-inline {
  display: inline-block;
}

.ui.red.basic.icon.labeled.button > i.icon {
  background-color: #ff929559;
}

.ui.disabled.checkbox:has(input[type="checkbox"]:not(:checked)),
.ui.disabled.checkbox:has(input[type="radio"]:not(:checked))
{
  opacity: 0.5;
}