#heading {
}

#subheading > p {
  margin: 0 0 0.5rem 0;
}

#subheading > p:last-child {
  margin: 0 0 0 0;
}
