body {
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
.header {
  font-family: "Bitter", sans-serif !important;
}

@import url("https://fonts.googleapis.com/css?family=Bitter:700|Lato");
