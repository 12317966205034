#sort-options {
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  column-gap: 0.5rem;
}

#sort-options > .ui.input {
  max-width: 12rem;
}

#sort-options > .ui.buttons,
#sort-options > .ui.input {
  margin: auto;
}

#sort-options > .ui.input > input {
  border: 1px #444444 solid !important;
  height: 32.5px;
}
#sort-options > .ui.input > input::placeholder {
  color: gray;
}
#sort-options > .ui.input > input:focus::placeholder {
  color: rgb(74, 74, 74);
}

#sort-options a.ui.basic.active.button.bg.color2,
#sort-options a.ui.basic.active.button.bg.color3 {
  background: #ebebeb !important;
}

#sort-options a.ui.basic.button.bg:not(.active) {
  background: #ffffff !important;
}

@media only screen and (max-device-width: 500px) {
  #sort-options {
    flex-direction: column;
    row-gap: 0;
  }
  #sort-options > .ui.buttons {
    width: 100%;
  }
  #sort-options.double > .ui.buttons:first-child > .ui.button {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  #sort-options.double > .ui.buttons:last-child > .ui.button {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: none !important;
  }
  #sort-options > .ui.buttons > .ui.button:first-child {
    max-width: 2.5rem;
  }

  #sort-options.double.filter > .ui.buttons:first-child > .ui.button {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  #sort-options.double.filter > .ui.buttons:nth-child(2) > .ui.button {
    border-radius: 0;
    border-top: none !important;
    border-bottom: none !important;
  }
  #sort-options.double.filter > .ui.input > input {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  #sort-options > .ui.input {
    width: 100%;
    max-width: none;
  }
}
