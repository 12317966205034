#logo {
  padding: 2rem 0 0 0;
}

i.bordered.icon {
  width: 1.5em;
  height: 1.5em;
  padding: 0.25em 0 !important;
  border-radius: 0.05em;
  box-shadow: 0 0 0 0.075em rgba(0, 0, 0, 0.075) inset;
}

#signup .field {
  margin-bottom: 0 !important;
}

#signup .signupValidityLabel {
  margin-bottom: 1rem !important;
}

#signup .signupValidityLabel:before {
  border-width: 0;
  left: 1rem;
}

.field > .ui.checkbox.no-margin-bottom {
  margin-bottom: 0;
}
