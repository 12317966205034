.dropdownDatePickerContainer > select {
  font-size: 1.1rem;
  margin: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
  border: solid #cecece 1px;
  min-width: 10rem;
  cursor: pointer;
}

.dropdownDatePickerContainer > select:first-child {
  margin-left: 0;
}

.dropdownDatePickerContainer > select:last-child {
  margin-right: 1rem;
}

.dropdownDatePickerContainer {
  display: inline-block;
}
