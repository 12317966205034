#top-bar {
  position: absolute;
  top: 0;
  z-index: 10;
  width: 100%;
}

#top-bar-hidden {
  visibility: hidden;
}

#top-bar > .ui.inverted.menu .item:before {
  display: none;
}

#top-bar > .ui.inverted.ui.container.menu {
  background: none;
  margin-top: 0;
  margin-bottom: 0;
}

@media (hover: on-demand), (any-hover: none) {
  #top-bar .ui.inverted.button:hover {
    box-shadow: 0 0 0 2px #fff inset !important;
    background: transparent none;
    color: #fff;
    text-shadow: none !important;
  }
}
